/* Fuente Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

/* Fuente Now */
@font-face {
    font-family: "Now";
    src: url('./NowFont/Now-Regular.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url('./NowFont/Now-Bold.woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url('./NowFont/Now-Medium.woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url('./NowFont/Now-Light.woff');
    font-weight: 300;
    font-style: normal;
}