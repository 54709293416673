.Banner {
    height: 400px;
    position: relative;
}

.Media {
    background-color: white;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: 300ms;
    cursor: pointer;
}

.MediaCaption {
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    padding: 15px;
    background-color: black;
    color: white;
    opacity: 0.6;
    width: 100%;
    height: 10%;
}

.Media:hover{
    filter: brightness(115%);
}

.BannerGrid {
    height: 100%;
    position: relative;
}

.Content {
    color: white;
    background-color: rgb(0, 167, 211);
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: 300ms;
}

.Content .Title {
    font-size: 30px;
    font-weight: 500;
    color: white;
}

.Content .Caption {
    margin-top: 10px;
    font-size: 18px;
    color: white;
}

.Content .ViewButton {
    margin-top: 40px;
    padding: 0.8rem 3rem;
    color: white;
    font-size: 25px;
    text-transform: capitalize;
    transition: 200ms;
}

/* 
.ViewButton:hover, .ViewButton:active {
    background-color: rgb(241, 241, 241);
    color: rgb(119, 24, 24)
} */